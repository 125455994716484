@font-face {
    font-family: "Hellix";
    src: url(../fonts/Hellix-Regular.woff) format("woff");
}
@font-face {
    font-family: "Hellix Bold";
    src: url(../fonts/Hellix-Bold.woff) format("woff");
}
@font-face {
    font-family: "Hellix SemiBold";
    src: url(../fonts/Hellix-SemiBold.woff) format("woff");
}
@font-face {
    font-family: "Hellix ExtraBold";
    src: url(../fonts/Hellix-ExtraBold.woff) format("woff");
}



$desktopWidth: 1024px;
$tabletWidth: 700px;
$phoneWidth: 480px;
$noteFont: "Hellix", "Nunito", sans-serif;
$noteBlackFont: "Hellix Black", "Nunito", sans-serif;
$noteHeaderFont: "Hellix ExtraBold", "Nunito", sans-serif;
$noteBoldFont: "Hellix SemiBold", "Nunito", sans-serif;
$grey-bg: #f7f3ff;
$accent-purple: #3722f6;
$accent-purple-2: #e98df5;

.arc-img {
    max-width: 200px;
}

.note-footer {
    font-family: $noteFont;
    & a {
        &:hover {
            text-decoration: underline;
            text-decoration-color: $accent-purple;
            text-decoration-thickness: 1px;
            color: $accent-purple;
        }
    }

}

.markdown-holder {
    &.notes {

        @media (min-width: $tabletWidth) {
            max-width: 670px;
            margin-left: calc((100% - 670px)/2);
        }

        &.toc {
            margin-bottom: 0;
            padding-bottom: 0;
        }

        & p, li, table, th, td {
            font-size: 1.2rem;
            font-family: $noteFont;
            font-weight: 500;
            & strong {
                font-weight: 800;
                text-shadow: none;
            }
        }
        & p {
            white-space: pre-wrap;
        }
        & th {
            font-weight: 800;
        }
        & strong, & b {
            font-family: $noteBoldFont;
        }
        & li {
            margin-top: 8px;
        }
        & h1+h3, h1+h2, h1+h3, h1+h4, h2+h3, h2+h4, h3+h4 {
            margin-top: .5rem;
        }
        & h1, h2, h3, h4, h5, h6 {
            font-family: $noteHeaderFont;
            font-weight: 800;
        }
        & li > code, p > code {
            background: $grey-bg;
            padding: 0 8px;
            border-radius: 4px;
            color: $accent-purple;
            font-size: 90%;
            word-wrap: break-word;
        }
        & h1 {
            font-size: 2.6rem;
            font-weight: 1000;
            margin-bottom: 0;
        }
        & h2 {
            font-size: 2.0rem;
            margin-bottom: 0;
            margin-top: 1em;
        }
        & h3 {
            font-size: 1.6rem;
            margin-bottom: 0;
            margin-top: 1em;
        }
        & h4 {
            font-size: 1.2rem;
            margin-bottom: 0;
            margin-top: 1em;
        }
        & blockquote {
            background: $grey-bg;
            border-left: 6px solid $accent-purple;
            margin: 0;
            padding: .2em 2em;
        }
        & pre {
            background: rgb(40, 39, 39);
            padding: 1em 2em;
            border-radius: 4px;
            white-space: pre-wrap;
            word-wrap: break-word;
            & code { color: rgb(201, 201, 201)}
        }
        span.hashtag {
            background: $accent-purple;
            border-radius: 1em;
            padding: 2px 12px;
            font-size: .8em;
            color: #fff;
        }
        & img {
            border: 1px solid lightgrey;
            margin-top: 1em;
        }
        & span.hljs-keyword, span.hljs-params {
            color: rgb(153, 197, 255);
        }
        & span.hljs-number {
            color: rgb(163, 120, 255);
        }
        & span.hljs-operator {
            color: rgb(239, 86, 101);
        }
        & span.hljs-function, span.hljs-title {
            color: rgb(255, 241, 147);
        }
        & span.hljs-string {
            color: rgb(104, 255, 162);
        }
        & span.hljs-comment {
            color: rgb(75, 75, 75)
        }

        & li.task-list-item {
            list-style: none;
        }
        & > ul.contains-task-list {
            margin-left: -1.6em;
        }

        & span.checkbox {
            width: 0.7em;
            height: 0.7em;
            margin-bottom: -3px;
            content: " ";
            display: inline-block;
            border: 2px solid $accent-purple;
            border-radius: 3px;
            margin-right: 4px;
            &.checked {
                background-color:$accent-purple;
                content: "X";
                color:#fff;
            }
        }

        & a {
            // font-weight:400;
            text-decoration-color: $accent-purple;
            text-decoration-thickness: 2px;
            &:hover {
                color: $accent-purple;
                text-decoration-style: solid;
            }
        }

        padding-bottom: 100px;
    }
}

@media print {
    div.markdown-holder {
        margin: 0 !important;
        padding: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
    }
    h1, h2, h3, h4, h5 {
        page-break-after: avoid;
    }
    pre, pre > code, blockquote, tr, th {
        page-break-inside: avoid;
    }
    @page {
        margin: 20mm 15mm 20mm 15mm;  
    }
    // ul, ol {
    //     page-break-before: avoid;
    // }
}