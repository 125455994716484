// @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@0,400;0,600;0,900;1,600;1,400;1,900&display=swap');

$primary-color: #CC8899;
$accent-color: #ffe600;
$highlight-color: #fdff85;
$highlight-color-2: #ffae79;
$underline-color: #ff74d5;
$accent-red: #fc274a;
$black: #000000;
$white: #FFFFFF;
$tileHeight: 16rem;
$headerFont: "Bebas Neue", sans-serif;
$bodyFont: "Merriweather", sans-serif;
$miscFont: "Raleway", sans-serif;
$blackText: rgba(0,0,0,.85);

$desktopWidth: 1024px;
$tabletWidth: 700px;
$phoneWidth: 480px;

h1, h2, h3, h4, h5, h6 {
    font-family: $headerFont;
}
.markdown-holder {
    & p, li, table, th, td {
        font-size: 1.2rem;
        font-family: $bodyFont;
        font-weight: 400;
        & strong {
            font-weight: 600;
            text-shadow: 2px 2px 0 $highlight-color;
        }
    }
    & a {
        text-decoration: underline;
        text-decoration-color: $underline-color;
        text-decoration-thickness: 4px;
        font-weight: 600;
        &:hover {
            text-decoration-style: wavy;
        }
    }
    & p {
        margin-top: 0.7em;
    }
    & h1 {
        font-size: 6rem;
        font-weight: 400;
        &:first-child {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    & h2 {
        margin-top: .5rem;
        font-size: 4rem;
    }
    & h3 {
        font-size: 3rem; 
        font-weight: 400;
        margin-top: .5rem;
        margin-bottom: 0;
    }
    & h4 {
        font-size: 2rem; 
        font-weight: 400;
        margin-top: .5rem;
        margin-bottom: 0;
    }
    & h3 > em {
        display: block;
        font-size: 2rem;
    }
    & hr {
        width: 100%;
        margin-top: 2rem;
    }
    &.playlist-gallery {
        & h3 {
            text-shadow: 2px 2px 0 $highlight-color-2;
            & > em {
                text-shadow: none;
            }
        }
        & iframe {
            border-radius: 8px;
        }
        & div.no-playlist {
            font-size: 4rem;
            font-family: $headerFont;
            background: linear-gradient(0deg, #FDBB2D 0%, #22C1C3 100%);
            border-radius: 8px;
            padding: 2rem;
            text-align: center;
        }
    }
    &.flobo-gallery {
        & h3 {
            text-align: right;
            & > em {
                color: $accent-red !important;
            }
        }
    }
    & img {
        border: 6px solid black;
        &.flobo-head {
            max-width: 400px;
            filter: saturate(0) contrast(1.2) brightness(2.5) drop-shadow(24px 24px 0 $accent-color) drop-shadow(-24px 24px 0 $accent-red);
            transform: rotate(-30deg);
            margin-top: 2rem;
            margin-bottom: -2rem;
            border: none;
        }
    }

    & table, td, th {
        border: 1px solid #b1b1b1;
        border-collapse: collapse;
        padding: 6px 12px;
        text-align: left;
    }

}

@media print {
    & div.floating-link {
        display: none;
        visibility: hidden;
    }
}

form.jotform-form {
    background: #FFF;
}

img.home-image {
    max-width: 100%;
    transform: rotate(-15deg);
    max-height: 50vh;
}
img.project-image {
    width: 100%;
    border: 3px solid $black;
}
a {
    text-decoration: none;
    color: inherit;
    &:visited {
        color: inherit;
    }
}
div[role='group'].floating-link {
    position: fixed;
    left: 1rem;
    bottom: 1rem;
    &>button {
        background-color: $white;
    }
    &>a:not(:first-of-type) {
        margin-left: -2px;
    }
    z-index: 999;
}
div.project-tile {
    height: $tileHeight;
    border: 3px solid $black;
    position: relative;
    &:hover {
        box-shadow: .4em .4em 8px rgba(0,0,0,.4), .7em .7em 0 $underline-color, -.7em -.7em 0 $highlight-color;
        &>div.project-tile-desc {
            transform: rotate(-8deg);
        }
    }
    &>div {
        &.project-tile-title {
            position: relative;
            top: 0;
            left: 0; 
            background: $white;
            padding: .5rem 1rem;
            border: none;
            border-bottom: 3px solid black;
            z-index: 99;
            height: auto;
            &>h4 {
                font-family: $miscFont;
                text-transform: uppercase;
                font-weight: 900;
                color: $blackText;
                font-size: 1.4em;
            }
        }
        &.project-tile-desc {
            position: absolute;
            width: 70%;
            background: $white;
            bottom: 12px;
            right: 12px;
            padding: .2rem .2rem;
            border: 3px solid black;
            z-index: 99;
            &>p {
                font-family: $miscFont;
                font-weight: 700;
                color: $blackText;
            }   
        }
        &.project-tile-background {
            position: absolute;
            width: 100%;
            height: $tileHeight;
            overflow: hidden;
            font-size:2.2em;
            text-align:center;
        }
    }
}

#contact-iframe {
    width: 100%;
    min-height: 1000px;
}

button.sat-nav-link {
    position: fixed;
    border-radius: 50%;
    border: 2px solid $black;
    background: $white;
    &:hover {
        background: $white;
    }
}
